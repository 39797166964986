<template>
  <v-container>
    <v-card>
      <v-card-title class="headline d-flex font-weight-thin" :class="titleColor"> Соискатели </v-card-title>
      <v-card-text class="pa-0">
        <v-text-field
          v-if="!tableLoading"
          v-model="searchEmps"
          append-icon="mdi-magnify"
          class="mb-5 px-5"
          label="Поиск.."
          single-line
          hide-details
        />
        <v-progress-linear v-if="tableLoading && isMobile" indeterminate :color="loadingColor" />
        <v-data-table
          v-else-if="!tableLoading"
          :headers="headers"
          :items="candidatesList"
          :items-per-page="10"
          :page="page"
          class="elevation-0"
          no-data-text="нет данных"
          :loading="tableLoading"
          loading-text="Загрузка данных.."
          disable-sort
          :search="searchEmps"
          @page-count="pageCount = $event"
          hide-default-footer
        >
          <template v-slot:item.credate="{ item }">
            {{ normalizeDate(item.credate) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="editCandidat(item.id)">
              <v-icon> mdi-square-edit-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div v-if="pageCount" class="text-center my-3 py-3" :class="isMobile ? 'leftMinus' : ''">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7" circle />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'candidates',
    data() {
      return {
        pageCount: 0,
        searchEmps: null,
        page: 1,
        tableLoading: true,
        toolbarcolor: process.env.VUE_APP_THEME_COLOR,
        amount: 10,
        headers: [
          {
            text: 'ЛН',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'ФИО', value: 'fio' },
          { text: 'Дата создания', value: 'credate' },
          { text: 'Телефон', value: 'contact_title' },
          { text: 'Детали', value: 'action' },
        ],
      }
    },
    mounted() {
      this.$store.dispatch('loadCandidatesList').then(() => {
        this.tableLoading = false
      })
    },
    computed: {
      ...mapGetters({
        checks: 'checks',
        selectMsg: 'rotDetail',
        titleColor: 'titleColor',
        loadingColor: 'loadingColor',
        candidatesList: 'ApplicantsList',
      }),
      isMobile() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return true
          case 'sm':
            return true
          case 'md':
            return false
          case 'lg':
            return false
          case 'xl':
            return false
          default:
            return false
        }
      },
    },
    methods: {
      editCandidat(id) {
        console.log('id', id)
        Promise.all([
          this.$store.dispatch('loadProfileData', id),
          this.$store.dispatch('loadCandidateContactsList', id),
          this.$store.dispatch('loadEmpDocTypesList'),
          this.$store.dispatch('loadCandidateDocList', id),
          this.$store.dispatch('setCandidateDocList', id),
          this.$store.dispatch('getAvatar', id),
          this.$store.dispatch('loadCandidateFiles', id),
        ]).then(results => {
          console.log('results', results)
        })
      },
      normalizeDate(date) {
        if (!date) return ''
        let normalDate = date.split(' ')[0].split('-').reverse().join('.')
        return normalDate
      },
    },
  }
</script>
